import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: ${(props) => props.theme.body};

  @media (max-width: 1024px) {
    height: auto;
    padding-top: 3rem;
    margin-bottom: 3rem;
  }
`;
export const ServiceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: top;
  color: ${(props) => props.theme.text};
`;

export const ServiceTitle = styled(motion.h3)`
  padding: 1rem;
  text-align: left;
  width: 90%;
  font-size: 20px;

  @media (max-width: 1024px) {
    font-size: 16px;
    width: 80%;
    padding: 0.5rem;
  }
`;

export const ServiceInfo = styled(motion.p)`
  padding: 1rem;
  margin-bottom: 0.5rem;
  text-align: left;
  width: 90%;
  border-top: 2px solid ${(props) => props.theme.text};

  @media (max-width: 1024px) {
    font-size: 13px;
    padding: 0.5rem;
    width: 80%;
  }
`;

export const Title = styled(motion.h1)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  color: ${(props) => props.theme.text};
  border-bottom: 1px solid ${(props) => props.theme.text};
  font-size: 40px;
  margin-bottom: 3rem;
`;

export const ServiceItem = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 12px;
  padding: 1rem;
  margin: 1rem;
  cursor: pointer;

  &:hover {
    scale: 1.02;
    transition-duration: 0.12s;
    transition-delay: 0.08s;
  }

  @media (max-width: 768px) {
    width: 90%;
    margin: 0.5rem;
  }
`;
