import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.body};
  overflow: auto;
  z-index: 1000;
  overflow-x: hidden;
`;

export const VideoContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0;
  padding: 5rem 0;
  position: relative;
  object-fit: contain;
`;

export const ImageContainer = styled.div`
  width: 60%;
  height: 24rem;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0.5;

  &:hover {
    scale: 1.03;
    transition: 0.5s;
    transition-delay: 0s;
    opacity: 0.9;
  }
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.text};
  position: absolute;
  left: 15%;
  top: 60%;
  font-size: 60px;
  z-index: 10;
  text-shadow: 3px 3px 3px ${(props) => props.theme.body};
`;

export const PlayIcon = styled.h1`
  color: ${(props) => props.theme.text};
  position: absolute;
  z-index: 10;
  text-shadow: 3px 3px 3px ${(props) => props.theme.body};
  opacity: 0.75;
  &:hover {
    scale: 1.03;
    transition: 0.5s;
    transition-delay: 0s;
    opacity: 0.9;
    color: ${(props) => props.theme.body};
  }
`;

export const Nav = styled.div`
  width: 98%;
  height: 4rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1000;
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoImg = styled(motion.img)`
  width: 3rem;
  height: 3rem;
  object-fit: contain;
  overflow: hidden;
  margin-right: 1rem;

  &:hover {
    scale: 1.02;
    transition-duration: 0.12s;
    transition-delay: 0.08s;
  }

  @media (max-width: 1024px) {
    width: 3rem;
  }
`;

export const LogoText = styled(motion.img)`
  height: 3rem;
  width: 14rem;
  object-fit: contain;

  &:hover {
    scale: 1.02;
    transition-duration: 0.12s;
    transition-delay: 0.08s;
  }

  @media (max-width: 1024px) {
    width: 12rem;
  }
`;

export const Logo2 = styled.img`
  width: 20rem;
  height: 4rem;
  object-fit: contain;
  margin-left: 1px;
  margin-top: 1px;
  position: absolute;

  &:hover {
    scale: 1.02;
    transition-duration: 0.12s;
    transition-delay: 0.08s;
  }

  @media (max-width: 1024px) {
    width: 16rem;
  }
`;

export const Menu = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const MenuItem = styled(motion.li)`
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: ${(props) => props.theme.text};
  text-shadow: 2px 2px 3px rgba(32, 32, 32, 0.7);
  cursor: pointer;

  &:hover {
    scale: 1.03;
    transition-duration: 0.12s;
    transition-delay: 0.08s;
  }
`;

export const HamburgerMenu = styled(motion.div)`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.text};

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const HamburgerMenuItems = styled.ul`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.body};
  position: absolute;
  top: 0;
  z-index: 10;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ExitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.text};
  position: absolute;
  top: 0.5rem;
  left: 0;
  overflow: hidden;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.h1`
  color: ${(props) => props.theme.text};
  font-size: 48px;
  font-weight: 700;
  margin-top: 1rem;
  width: 100%;
  text-align: right;
  margin-right: 4rem;

  @media only screen and (max-width: 1024px) {
    font-size: 30px;
    margin-right: 2rem;
  }
`;
