import "./App.css";
import Nav from "./components/Nav";
import Hero from "./components/Hero";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import Language from "./components/Language";
import About from "./components/About";
import Service from "./components/Service";
import Footer from "./components/Footer";
import GlobalStyles from "./styles/GlobalStyles";
import Country from "./components/Country";
import Videos from "./components/Videos";
import Images from "./components/Images";
import China from "./components/Country/China";
import Japan from "./components/Country/Japan";
import Us from "./components/Country/Us";

import { dark } from "./styles/Themes";
import { ThemeProvider } from "styled-components";
import Statement from "./components/Statement";
import { motion, AnimatePresence } from "framer-motion";

function App() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(false);

  const [china, setChina] = useState(true);
  const [japan, setJapan] = useState(false);
  const [us, setUs] = useState(false);

  const [playVideo_dzzit, setPlayVideo_dzzit] = useState(false);
  const [playVideo_guyu, setPlayVideo_guyu] = useState(false);
  const [playVideo_libresse, setPlayVideo_libresse] = useState(false);
  const [playVideo_skinC, setPlayVideo_skinC] = useState(false);
  const [playVideo_hyatt, setPlayVideo_hyatt] = useState(false);
  const [playVideo_romago1, setPlayVideo_romago1] = useState(false);
  const [playVideo_romago2, setPlayVideo_romago2] = useState(false);
  const [playVideo_neta, setPlayVideo_neta] = useState(false);
  const [playVideo_omega1, setPlayVideo_omega1] = useState(false);
  const [playVideo_uo, setPlayVideo_uo] = useState(false);
  const [playVideo_uo2, setPlayVideo_uo2] = useState(false);

  const [displayImg_fila, setDisplayImg_fila] = useState(false);
  const [displayImg_flats, setDisplayImg_flats] = useState(false);

  // Video Links
  const dzzit1link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/dzzit1.MP4";
  const guyu1link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/guyu1lowRes.mp4";
  const hyatt1link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/hyatt.MP4";
  const libresse1link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/libresse1lowRes.mp4";
  const romago1link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/romago1lowRes.mp4";
  const romago2link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/romago2lowRes.mp4";
  const skinC1link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/skinCeuticals1lowRes.mp4";
  const neta1link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/neta2lowRes.mp4";
  const omega1link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/omega1.mp4";
  const uo1link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/uo1.mov";
  const uo2link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/uo2lowRes.mp4";

  // Photo Links

  // ============= flats ===============
  const flat1link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/flats/flat1lowRes.jpg";
  const flat2link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/flats/flat2lowRes.jpg";
  const flat3link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/flats/flat3lowRes.jpg";
  const flat4link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/flats/flat4lowRes.jpg";
  const flat5link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/flats/flat5lowRes.jpg";
  const flat6link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/flats/flat6lowRes.jpg";
  const flat7link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/flats/flat7lowRes.jpg";
  const flat8link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/flats/flat8lowRes.jpg";
  const flat9link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/flats/flat9lowRes.jpg";
  const flat10link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/flats/flat10lowRes.jpg";

  // ============= neta ===============
  const fila1link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/fila/fila1lowRes.jpg";
  const fila2link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/fila/fila2lowRes.jpg";
  const fila3link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/fila/fila3lowRes.jpg";
  const fila4link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/fila/fila4lowRes.jpg";
  const fila5link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/fila/fila5lowRes.jpg";
  const fila6link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/fila/fila6lowRes.jpg";
  const fila7link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/fila/fila7lowRes.jpg";
  const fila8link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/fila/fila8lowRes.jpg";
  const fila9link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/fila/fila9lowRes.jpg";
  const fila10link =
    "https://ksproduction-1318064570.cos.ap-chengdu.myqcloud.com/ksproudction/photos/fila/fila10lowRes.jpg";



  const filaPhotos = [
    fila1link,
    fila2link,
    fila3link,
    fila4link,
    fila5link,
    fila6link,
    fila7link,
    fila8link,
    fila9link,
    fila10link,
  ];

  const flatPhotos = [
    flat1link,
    flat2link,
    flat3link,
    flat4link,
    flat5link,
    flat6link,
    flat7link,
    flat8link,
    flat9link,
    flat10link,
  ];

  const toggleVideo_dzzit = () => {
    setPlayVideo_dzzit(true);
  };

  const toggleVideo_guyu = () => {
    setPlayVideo_guyu(true);
  };

  const toggleVideo_libresse = () => {
    setPlayVideo_libresse(true);
  };

  const toggleVideo_skinC = () => {
    setPlayVideo_skinC(true);
  };

  const toggleVideo_romago1 = () => {
    setPlayVideo_romago1(true);
  };

  const toggleVideo_romago2 = () => {
    setPlayVideo_romago2(true);
  };

  const toggleVideo_hyatt = () => {
    setPlayVideo_hyatt(true);
  };

  const toggleVideo_omega1 = () => {
    setPlayVideo_omega1(true);
  };

  const toggleVideo_uo = () => {
    setPlayVideo_uo(true);
  };

  const toggleVideo_uo2 = () => {
    setPlayVideo_uo2(true);
  };

  const toggleVideo_neta = () => {
    setPlayVideo_neta(true);
  };

  const toggleImg_fila = () => {
    setDisplayImg_fila(true);
  };

  const toggleImg_flats = () => {
    setDisplayImg_flats(true);
  };

  const exitVideo = () => {
    setPlayVideo_dzzit(false);
    setPlayVideo_guyu(false);
    setPlayVideo_libresse(false);
    setPlayVideo_skinC(false);
    setPlayVideo_romago1(false);
    setPlayVideo_romago2(false);
    setDisplayImg_fila(false);
    setPlayVideo_hyatt(false);
    setPlayVideo_omega1(false);
    setPlayVideo_uo(false);
    setPlayVideo_uo2(false);
    setPlayVideo_neta(false);
    setDisplayImg_flats(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(true);
  };

  const languageVariant = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const mainContentVariant = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={dark}>
        <AnimatePresence>
          {language ? (
            <motion.div
              key="mainContent"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={mainContentVariant}
              transition={{ duration: 0.5 }}
            >
              {playVideo_dzzit && (
                <Videos
                  exitVideo={exitVideo}
                  video={dzzit1link}
                  title="d'zzit"
                />
              )}
              {playVideo_guyu && (
                <Videos exitVideo={exitVideo} video={guyu1link} title="谷雨" />
              )}
              {playVideo_libresse && (
                <Videos
                  exitVideo={exitVideo}
                  video={libresse1link}
                  title="Libresse (薇尔)"
                />
              )}
              {playVideo_skinC && (
                <Videos
                  exitVideo={exitVideo}
                  video={skinC1link}
                  title="Skin Ceuticals (修丽可)"
                />
              )}
              {playVideo_hyatt && (
                <Videos
                  exitVideo={exitVideo}
                  video={hyatt1link}
                  title="Park Hyatt Kyoto"
                />
              )}
              {playVideo_romago1 && (
                <Videos
                  exitVideo={exitVideo}
                  video={romago1link}
                  title="Romago (雷米格)"
                />
              )}
              {playVideo_romago2 && (
                <Videos
                  exitVideo={exitVideo}
                  video={romago2link}
                  title="Romago (雷米格)"
                />
              )}
              {playVideo_uo && (
                <Videos
                  exitVideo={exitVideo}
                  video={uo1link}
                  title="uo (由布岳)"
                />
              )}
              {playVideo_uo2 && (
                <Videos
                  exitVideo={exitVideo}
                  video={uo2link}
                  title="uo (由布岳)"
                />
              )}
              {playVideo_neta && (
                <Videos
                  exitVideo={exitVideo}
                  video={neta1link}
                  title="Neta Auto (哪吒汽车)"
                />
              )}
              {playVideo_omega1 && (
                <Videos
                  exitVideo={exitVideo}
                  video={omega1link}
                  title="Omega (欧米茄)"
                />
              )}
              {displayImg_fila && (
                <Images
                  exitVideo={exitVideo}
                  photos={filaPhotos}
                  title="Fila Golf"
                />
              )}
              {displayImg_flats && (
                <Images
                  exitVideo={exitVideo}
                  photos={flatPhotos}
                  title="Flats (平面)"
                />
              )}
              <Nav t={t} />
              <Hero id="home" t={t} />
              <About t={t} />

              <Country
                id="gallery"
                china={china}
                setChina={setChina}
                japan={japan}
                setJapan={setJapan}
                us={us}
                setUs={setUs}
                t={t}
              />
              {china && (
                <China
                  t={t}
                  toggleVideo_dzzit={toggleVideo_dzzit}
                  toggleVideo_guyu={toggleVideo_guyu}
                  toggleVideo_libresse={toggleVideo_libresse}
                  toggleVideo_skinC={toggleVideo_skinC}
                  toggleImg_fila={toggleImg_fila}
                  toggleVideo_neta={toggleVideo_neta}
                  toggleVideo_omega1={toggleVideo_omega1}
                />
              )}
              {japan && (
                <Japan
                  t={t}
                  toggleVideo_hyatt={toggleVideo_hyatt}
                  toggleVideo_uo={toggleVideo_uo}
                  toggleVideo_uo2={toggleVideo_uo2}
                  toggleImg_flats={toggleImg_flats}
                />
              )}
              {us && (
                <Us
                  toggleVideo_romago1={toggleVideo_romago1}
                  toggleVideo_romago2={toggleVideo_romago2}
                  t={t}
                />
              )}
              <Statement t={t} />
              <Service id="service" t={t} />

              <Footer t={t} />
            </motion.div>
          ) : (
            <motion.div
              key="language"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={languageVariant}
              transition={{ duration: 0.5 }}
            >
              <Language t={t} changeLanguage={changeLanguage} />
            </motion.div>
          )}
        </AnimatePresence>
      </ThemeProvider>
    </>
  );
}

export default App;
