import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
// Font Lato

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');
// Font Kanit
// font-family: 'Kanit', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap');
// Font Indie Flower
// font-family: 'Indie Flower', cursive;
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
// Font Roboto
// font-family: 'Roboto', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');




*,*::before,*::after{
    margin: 0;
    padding: 0;
}

body{
    /* font-family: 'Lato', sans-serif; */
    /* font-family: 'Indie Flower', cursive; */
    /* font-family: 'Kanit', sans-serif; */
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    background-color: rgba(32,32,32,1);

    // Custom Scroll Bar
    &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }
}
h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}

a{
    color: inherit;
    text-decoration: none;
}

`;
export default GlobalStyles;
