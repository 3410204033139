import React from "react";
import { Container } from "./StatementElements";
import CoverVideo2 from "../CoverVideo2";
import { motion } from "framer-motion";

const index = ({ t }) => {
  return (
    <Container id="statment">
      <motion.h2
        initial={{ opacity: 0, scale: 1 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}
        transition={{
          duration: 1.7,
          ease: "easeIn",
          delay: 0.3,
        }}
      >
        {t("companyStatement")}
      </motion.h2>
      <CoverVideo2 />
    </Container>
  );
};

export default index;
