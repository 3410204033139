import React from "react";
import {
  Container,
  VideoContainer,
  ImageContainer,
  Title,
  PlayIcon,
  Nav,
  LeftContainer,
  MenuItem,
  LogoContainer,
  LogoImg,
  LogoText,
  Header,
} from "./VideoElements";
import { BiExit } from "react-icons/bi";
import logoImg from "../../image/Logo/logoImg_light.png";
import logoText from "../../image/Logo/logoText_light.png";
import { Link } from "react-scroll";

const index = ({ exitVideo, video, title }) => {
  const initial = { opacity: 0.6, scale: 0.618 };
  const whileInView = { opacity: 1, scale: 1 };
  const viewport = { once: true };
  const transition = {
    duration: 0.8,
    ease: "easeInOut",
    mass: 0.3,
    delay: 0.2,
  };

  const initial2 = { opacity: 0, scale: 0.3};
  const animate2 = {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.8, ease: "easeIn" },
  };
  const exit2 = {
    opacity: 0,
    scale: 1,
    transition: { duration: 0.8, ease: "easeOut" },
  };

  return (
    <Container
      initial={initial2}
      animate={animate2}
      exit={exit2}
    >
      <Nav>
        <LeftContainer>
          <MenuItem>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <LogoContainer>
                <LogoImg
                  src={logoImg}
                  initial={{ opacity: 0, scale: 0, y: "-100", rotate: -180 }}
                  whileInView={{ opacity: 1, scale: 1, y: "0", rotate: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 2,
                    bounce: 0.3,
                    mass: 1,
                  }}
                />
                <LogoText
                  src={logoText}
                  initial={{ opacity: 0, scale: 1 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 2,
                    bounce: 0.3,
                    mass: 1,
                    delay: 2,
                  }}
                />
              </LogoContainer>
            </Link>
          </MenuItem>
        </LeftContainer>
        <BiExit size={36} color="white" onClick={() => exitVideo()} />
      </Nav>
      <VideoContainer
        initial={initial}
        whileInView={whileInView}
        viewport={viewport}
        transition={transition}
      >
        <video
          src={video}
          typeof="video/mp4"
          autoPlay
          playsInline
          webkit-playsinline="true"
          width="100%"
          controls
        />
        <Header>{title}</Header>
      </VideoContainer>
    </Container>
  );
};

export default index;
