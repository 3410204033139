import React from "react";
import {
  Container,
  Overlay,
  ContentContainer,
  ArrowContainer,
  InnerContainer,
} from "../Hero/HeroElements";
import CoverVideo from "../CoverVideo";
import { BsArrowDown } from "react-icons/bs";
import { motion } from "framer-motion";

const index = ({ t }) => {
  return (
    <Container id="home">
      <Overlay />

      <ContentContainer>
        <motion.h1
          initial={{ opacity: 0, scale: 0.6 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 3,
            type: "spring",
            ease: "easeIn",
            delay: 1,
          }}
        >
          {t("heroTitle")}
        </motion.h1>
        <motion.h5
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.7,
            ease: "easeOut",
            delay: 0.3
          }}
        >
          {t("tagline")}
        </motion.h5>
      </ContentContainer>
      <ArrowContainer
        animate={{
          y: [0, -20, 0],
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.25, 0.5, 0.75, 1],
          repeat: Infinity,
          repeatType: "loop",
          delay: 3,
        }}
      >
        <InnerContainer
          initial={{ opacity: 0, scale: 1 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 2,
            type: "spring",
            bounce: 0.3,
            mass: 1,
            delay: 3,
          }}
        >
          <BsArrowDown size={36} />
          <br />
          <h4>E x p l o r e</h4>
        </InnerContainer>
      </ArrowContainer>
      <CoverVideo />
    </Container>
  );
};

export default index;
