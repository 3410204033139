import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: ${(props) => props.theme.body};
  position: relative;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(32, 32, 32, 0.3);
  position: absolute;
  top: 0;
  z-index: 5;
`;

export const ContentContainer = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;
  color: ${(props) => props.theme.text};
  text-shadow: 2px 3px 5px ${(props) => props.theme.body};

  h1 {
    margin: 1rem;
    @media (max-width: 1024px) {
      width: 90%;
    }
  }

  h5 {
    margin: 0.5rem;
    font-weight: 400;
    color: rgba(128, 128, 128, 1);

    @media (max-width: 1024px) {
      width: 70%;
    }
  }
`;

export const ArrowContainer = styled(motion.div)`
  position: absolute;
  bottom: 2rem;
  border-radius: 50%;
  color: ${(props) => props.theme.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

export const InnerContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
