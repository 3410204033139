import React, { useState } from "react";
import {
  Container,
  LeftContainer,
  LogoImg,
  LogoText,
  Menu,
  MenuItem,
  HamburgerMenu,
  HamburgerMenuItems,
  ExitButtonContainer,
  LogoContainer,
} from "./NavElements";
import { RxHamburgerMenu } from "react-icons/rx";
import { BiExit } from "react-icons/bi";
import logoImg from "../../image/Logo/logoImg_light.png";
import logoText from "../../image/Logo/logoText_light.png";
import { Link } from "react-scroll";

const Index = ({ t }) => {
  const [showMenu, setShowMenu] = useState(false);

  const openMenu = () => {
    setShowMenu(true);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const initial = { opacity: 0, scale: 1, y: "-100" };
  const whileInView = { opacity: 1, scale: 1, y: "0" };
  const viewport = { once: true };
  const transition = {
    duration: 2,
    ease: "easeInOut",
    bounce: 0.5,
    mass: 0.3,
    delay: 0,
  };

  return (
    <Container>
      <LeftContainer>
        <MenuItem>
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={closeMenu}
          >
            <LogoContainer>
              <LogoImg
                src={logoImg}
                initial={{ opacity: 0, scale: 0, y: "-100", rotate: -180 }}
                whileInView={{ opacity: 1, scale: 1, y: "0", rotate: 0 }}
                viewport={{ once: true }}
                transition={{
                  duration: 2,
                  bounce: 0.3,
                  mass: 1,
                }}
              />
              <LogoText
                src={logoText}
                initial={{ opacity: 0, scale: 1 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{
                  duration: 2,
                  bounce: 0.3,
                  mass: 1,
                  delay: 2,
                }}
              />
            </LogoContainer>
          </Link>
        </MenuItem>
      </LeftContainer>
      <HamburgerMenu
        initial={initial}
        whileInView={whileInView}
        viewport={viewport}
        transition={transition}
        onClick={openMenu}
      >
        <RxHamburgerMenu size={24} color="rgba(128,128,128,1)" />
      </HamburgerMenu>

      {showMenu && (
        <HamburgerMenuItems>
          <ExitButtonContainer>
            <LogoContainer>
              <LogoImg src={logoImg} />
              <LogoText src={logoText} />
            </LogoContainer>
            <BiExit size={36} onClick={closeMenu} />
          </ExitButtonContainer>
          <MenuItem>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={closeMenu}
            >
              {t("menuHome")}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={closeMenu}
            >
              {t("menuAbout")}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              activeClass="active"
              to="gallery"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={closeMenu}
            >
              {t("menuGallery")}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              activeClass="active"
              to="service"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={closeMenu}
            >
              {t("menuService")}
            </Link>
          </MenuItem>
          {/* <MenuItem>
            <Link
              activeClass="active"
              to="quote"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={closeMenu}
            >
              {t("menuQuote")}
            </Link>
          </MenuItem> */}
        </HamburgerMenuItems>
      )}
      <Menu>
        <MenuItem
          initial={initial}
          whileInView={whileInView}
          viewport={viewport}
          transition={transition}
        >
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {t("menuHome")}
          </Link>
        </MenuItem>
        <MenuItem
          initial={initial}
          whileInView={whileInView}
          viewport={viewport}
          transition={transition}
        >
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={closeMenu}
          >
            {t("menuAbout")}
          </Link>
        </MenuItem>
        <MenuItem
          initial={initial}
          whileInView={whileInView}
          viewport={viewport}
          transition={transition}
        >
          <Link
            activeClass="active"
            to="gallery"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {t("menuGallery")}
          </Link>
        </MenuItem>
        <MenuItem
          initial={initial}
          whileInView={whileInView}
          viewport={viewport}
          transition={transition}
        >
          <Link
            activeClass="active"
            to="service"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {t("menuService")}
          </Link>
        </MenuItem>
        {/* <MenuItem>
          <Link
            activeClass="active"
            to="quote"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {t("menuQuote")}
          </Link>
        </MenuItem> */}
      </Menu>
    </Container>
  );
};

export default Index;
