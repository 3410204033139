import React from "react";
import {
  Container,
  ServiceTitle,
  ServiceContainer,
  ServiceInfo,
  Title,
  ServiceItem,
} from "./ServiceElements";

const index = ({ t }) => {
  const initial = { opacity: 0, scale: 1, y: 60 };
  const whileInView = { opacity: 1, scale: 1, y: 0 };
  const viewport = { once: true };
  const transition = {
    duration: 1,
    ease: "easeInOut",
    mass: 0.3,
    delay: 0,
  };

  const initial2 = { opacity: 0, scale: 1, y: 60 };
  const whileInView2 = { opacity: 1, scale: 1, y: 0 };
  const viewport2 = { once: true };
  const transition2 = {
    duration: 0.7,
    ease: "easeInOut",
    mass: 0.3,
    delay: 0.6,
  };

  const initial3 = { opacity: 0, scale: 1, x: -60 };
  const whileInView3 = { opacity: 1, scale: 1, x:0 };
  const viewport3 = { once: true };
  const transition3 = {
    duration: 0.7,
    ease: "easeInOut",
    mass: 0.3,
    delay: 0.3,
  };

  return (
    <Container id="service">
      <Title
        initial={initial}
        whileInView={whileInView}
        viewport={viewport}
        transition={transition}
      >
        {t("serviceTitle")}
      </Title>
      <ServiceContainer>
        <ServiceItem>
          <ServiceTitle
            initial={initial3}
            whileInView={whileInView3}
            viewport={viewport3}
            transition={transition3}
          >
            {t("service1tile")}
          </ServiceTitle>
          <ServiceInfo
            initial={initial2}
            whileInView={whileInView2}
            viewport={viewport2}
            transition={transition2}
          >
            {t("service1")}
          </ServiceInfo>
        </ServiceItem>
        <ServiceItem>
          <ServiceTitle
            initial={initial3}
            whileInView={whileInView3}
            viewport={viewport3}
            transition={transition3}
          >
            {t("service2tile")}
          </ServiceTitle>
          <ServiceInfo
            initial={initial2}
            whileInView={whileInView2}
            viewport={viewport2}
            transition={transition2}
          >
            {t("service2")}
          </ServiceInfo>
        </ServiceItem>
        <ServiceItem>
          <ServiceTitle
            initial={initial3}
            whileInView={whileInView3}
            viewport={viewport3}
            transition={transition3}
          >
            {t("service3tile")}
          </ServiceTitle>
          <ServiceInfo
            initial={initial2}
            whileInView={whileInView2}
            viewport={viewport2}
            transition={transition2}
          >
            {t("service3")}
          </ServiceInfo>
        </ServiceItem>
        <ServiceItem>
          <ServiceTitle
            initial={initial3}
            whileInView={whileInView3}
            viewport={viewport3}
            transition={transition3}
          >
            {t("service4tile")}
          </ServiceTitle>
          <ServiceInfo
            initial={initial2}
            whileInView={whileInView2}
            viewport={viewport2}
            transition={transition2}
          >
            {t("service4")}
          </ServiceInfo>
        </ServiceItem>
        <ServiceItem>
          <ServiceTitle
            initial={initial3}
            whileInView={whileInView3}
            viewport={viewport3}
            transition={transition3}
          >
            {t("service5tile")}
          </ServiceTitle>
          <ServiceInfo
            initial={initial2}
            whileInView={whileInView2}
            viewport={viewport2}
            transition={transition2}
          >
            {t("service5")}
          </ServiceInfo>
        </ServiceItem>
      </ServiceContainer>
    </Container>
  );
};

export default index;
