import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  h2 {
    width: 50%;
    text-align: center;
    line-height: 2;
    color: ${(props) => props.theme.text};
    font-weight: 400;
    z-index: 2;

    @media (max-width: 1024px) {
      width: 90%;
      font-size: 16px;
    }
  }
`;
