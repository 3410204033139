import React from "react";
import styled from "styled-components";
import Video2 from "../image/Video/camera2.mp4";

const VideoContainer = styled.section`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(32, 32, 32, 0.9);
`;

const CoverVideo2 = () => {
  return (
    <VideoContainer>
      <Overlay />
      <video
        src={Video2}
        typeof="video/mp4"
        autoPlay
        muted
        loop
        playsInline
        webkit-playsinline="true"
      />
    </VideoContainer>
  );
};

export default CoverVideo2;
