import React from "react";
import {
  Container,
  OptionContainer,
  OptionButton,
  ButtonContainer,
  LogoImg,
  LogoText,
  LogoContainer,
} from "./LanguageElements";
import Video from "../CoverVideo";
import logoImg from "../../image/Logo/logoImg_light.png";
import logoText from "../../image/Logo/logoText_light.png";

const index = ({ t, changeLanguage }) => {

  return (
    <Container>
      <Video />
      <LogoContainer>
        <LogoImg
          src={logoImg}
          initial={{ opacity: 0, scale: 1 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 3,
            bounce: 0.3,
            masss: 1,
          }}
        />
        <LogoText
          src={logoText}
          initial={{ opacity: 0, scale: 1 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 3,
            bounce: 0.3,
            masss: 1,
          }}
        />
      </LogoContainer>
      <OptionContainer
        initial={{ opacity: 0, scale: 0.9, y: 10 }}
        whileInView={{ opacity: 1, scale: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{
          duration: 2,
          bounce: 0.3,
          masss: 1,
          delay: 0.5,
        }}
      >
        <h3>Welcome, please select a language to begin:</h3>
        <br />
        <br />
        <ButtonContainer>
          <OptionButton onClick={() => changeLanguage("en")}>
            English
          </OptionButton>
          <OptionButton onClick={() => changeLanguage("zh")}>中文</OptionButton>
        </ButtonContainer>
      </OptionContainer>
    </Container>
  );
};

export default index;
