import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.body};
`;

export const OptionContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem;
  border-radius: 12px;
  z-index: 10;
  color: rgba(216, 216, 216, 1);
  font-size: 14px;

  @media (max-width: 1024px) {
    padding: 2rem 1rem;
  }
`;

export const OptionButton = styled.button`
  width: 8rem;
  height: 3rem;
  font-size: 18px;
  font-weight: 600;
  border-radius: 12px;
  margin: 0 1rem;
  border-style: none;
  background-color: transparent;
  color: rgba(216, 216, 216, 1);

  cursor: pointer;

  &:hover {
    scale: 1.03;
    transition-duration: 0.12s;
    border: 1px solid ${(props) => props.theme.text};
  }

  @media (max-width: 1024px) {
    width: 8rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 20rem;
  height: 4rem;
  object-fit: contain;
  margin-left: 0.5rem;
  z-index: 10;
  position: absolute;
  top: 0;

  &:hover {
    scale: 1.02;
    transition-duration: 0.12s;
    transition-delay: 0.08s;
  }

  @media (max-width: 1024px) {
    width: 16rem;
  }
`;

export const LogoImg = styled(motion.img)`
  width: 3rem;
  height: 3rem;
  object-fit: contain;
  overflow: hidden;
  margin-right: 1rem;

  &:hover {
    scale: 1.02;
    transition-duration: 0.12s;
    transition-delay: 0.08s;
  }

  @media (max-width: 1024px) {
    width: 3rem;
  }
`;

export const LogoText = styled(motion.img)`
  height: 3rem;
  width: 14rem;
  object-fit: contain;

  &:hover {
    scale: 1.02;
    transition-duration: 0.12s;
    transition-delay: 0.08s;
  }

  @media (max-width: 1024px) {
    width: 12rem;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
