import React, { useState } from "react";
import {
  Container,
  CountryContainer,
  CountryContainerSelected,
  Title,
  ImageContainer,
  SubContainer,
  Header
} from "./CountryElements";
import CHwhite from "../../image/Icons/CHwhite.png";
import USwhite from "../../image/Icons/USwhite.png";
import JPwhite from "../../image/Icons/JPwhite.png";
const index = ({ china, setChina, japan, setJapan, us, setUs, t }) => {
  const toggleChina = () => {
    setChina(true);
    setJapan(false);
    setUs(false);
  };
  const toggleJapan = () => {
    setChina(false);
    setJapan(true);
    setUs(false);
  };
  const toggleUs = () => {
    setChina(false);
    setJapan(false);
    setUs(true);
  };


  return (
    <Container id="gallery">
      <Header>{t("menuGallery")}</Header>
      <SubContainer>
        {china ? (
          <CountryContainerSelected>
            <ImageContainer>
              <img src={CHwhite} width="100%" alt=""/>
            </ImageContainer>
            <Title>{t("china")}</Title>
          </CountryContainerSelected>
        ) : (
          <CountryContainer onClick={() => toggleChina()}>
            <ImageContainer>
              <img src={CHwhite} width="100%" alt=""/>
            </ImageContainer>
            <Title>{t("china")}</Title>
          </CountryContainer>
        )}
        {japan ? (
          <CountryContainerSelected>
            <ImageContainer>
              <img src={JPwhite} width="60%" alt=""/>
            </ImageContainer>
            <Title>{t("japan")}</Title>
          </CountryContainerSelected>
        ) : (
          <CountryContainer onClick={() => toggleJapan()}>
            <ImageContainer>
              <img src={JPwhite} width="60%" alt=""/>
            </ImageContainer>
            <Title>{t("japan")}</Title>
          </CountryContainer>
        )}
        {us ? (
          <CountryContainerSelected>
            <ImageContainer>
              <img src={USwhite} width="100%" alt=""/>
            </ImageContainer>
            <Title>{t("us")}</Title>
          </CountryContainerSelected>
        ) : (
          <CountryContainer onClick={() => toggleUs()}>
            <ImageContainer>
              <img src={USwhite} width="100%" alt=""/>
            </ImageContainer>
            <Title>{t("us")}</Title>
          </CountryContainer>
        )}
      </SubContainer>
    </Container>
  );
};

export default index;
