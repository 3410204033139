import React from "react";
import styled from "styled-components";
import Banner from "./Banner";

import Facebook from "../../image/Icons/Facebook";
import Instagram from "../../image/Icons/Instagram";
import Twitter from "../../image/Icons/Twitter";
import LinkedIn from "../../image/Icons/LinkedIn";
import Youtube from "../../image/Icons/Youtube";
import Tiktok from "../../image/Icons/Tiktok";
import { Link } from "react-scroll";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillYoutube,
  AiFillTwitterCircle,
  AiFillWechat
} from "react-icons/ai";

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  position: relative;
  color: ${(props) => props.theme.text};
  overflow: hidden;
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  overflow: hidden;
`;
const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid ${(props) => props.theme.text};

  @media (max-width: 48em) {
    width: 90%;

    h1 {
      font-size: ${(props) => props.theme.fontxxxl};
    }
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 100%;
  }
`;

const IconList = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem auto;

  & > * {
    padding-right: 0.5rem;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const MenuItems = styled.ul`
  list-style: none;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;

  @media (max-width: 48em) {
    display: none;
  }
`;

const Item = styled.li`
  width: fit-content;
  cursor: pointer;

  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: ${(props) => props.theme.text};
    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }
`;

const Bottom = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  a {
    text-decoration: underline;
  }
  @media (max-width: 48em) {
    flex-direction: column;
    width: 100%;
    font-size: 13px;

    span {
      margin-bottom: 1rem;
    }
  }
`;
const TopDivider = styled.div`
  width: 100vw;
  height: 15vh;
  position: relative;
  top: 0;
  background-color: ${(props) => props.theme.pink};
`;

const MenuItem = styled.li`
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: ${(props) => props.theme.text};
  cursor: pointer;

  &:hover {
    scale: 1.03;
    transition-duration: 0.12s;
    transition-delay: 0.08s;
  }
`;

const Footer = ({ t }) => {
  return (
    <Section>
      <TopDivider />
      <Banner t={t} />
      <Container>
        <Left>
          <IconList>
            <a href="https://www.instagram.com/k.s_creative_production/?igshid=MjEwN2IyYWYwYw%3D%3D" target="_blank" rel="noopener">
              <AiFillInstagram size={24} />
            </a>
            <a href="" target="_blank" rel="noopener">
              <AiFillYoutube size={24} />
            </a>
            <a href="" target="_blank" rel="noopener">
              <AiFillWechat size={24} />
            </a>
          </IconList>
        </Left>
        <MenuItems>
          <MenuItem>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              {t("menuHome")}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              {t("menuAbout")}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              activeClass="active"
              to="gallery"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              {t("menuGallery")}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              activeClass="active"
              to="service"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              {t("menuService")}
            </Link>
          </MenuItem>
          {/* <MenuItem>
            <Link
              activeClass="active"
              to="quote"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              {t("menuQuote")}
            </Link>
          </MenuItem> */}
        </MenuItems>
      </Container>
      <Bottom>
        <span>
          &copy; {new Date().getFullYear()} K.S Creative Production LLC. All rights
          reserved.
        </span>
        <span>
          Made with &#10084; by{" "}
          <a href="" target="_blank" rel="noopener">
            PINKDIAMONDVVS
          </a>
        </span>
      </Bottom>
    </Section>
  );
};

export default Footer;
