import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.body};

`;

export const SubContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  overflow: hidden;
  padding: 3rem 0;
`;

export const CountryContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  object-fit: contain;
  overflow: hidden;
  padding: 1rem;
  opacity: 0.3;
  &:hover {
    scale: 1.03;
    transition: 0.5s;
    transition-delay: 0s;
  }
`;

export const CountryContainerSelected = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  object-fit: contain;
  overflow: hidden;
  padding: 1rem;
  opacity: 1;

  &:hover {
    scale: 1.03;
    transition: 0.5s;
    transition-delay: 0s;
  }
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.text};
  font-size: 36px;
  z-index: 10;
  margin-top: 1rem;
`;

export const Header = styled.h1`
  color: ${(props) => props.theme.text};
  z-index: 10;
  text-align: center;
  font-size: 40px;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.text};
`;

export const ImageContainer = styled.div`
  width: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
