import React from "react";
import styled from "styled-components";
import FooterVideo from "../FooterVideo";
import { motion } from "framer-motion";

const Section = styled.section`
  width: 100vw;
  height: 25rem;
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  @media (max-width: 48em) {
    height: 15rem;
    flex-direction: column;
  }
`;

const Title = styled(motion.h1)`
  width: 100%;
  font-size: ${(props) => props.theme.fontxxl};
  color: ${(props) => props.theme.text};
  padding: 1rem 5rem;
  z-index: 2;
  text-align: right;
  text-shadow: 2px 3px 5px rgba(64, 64, 64, 0.9);
  font-weight: 400;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxl};
    text-align: center;
    width: 40%;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
    padding: 2rem 0;

    width: 100%;
  }
`;

const Banner = ({ t }) => {
  return (
    <Section>
      <FooterVideo />
      <Title
        initial={{ opacity: 0, scale: 1, y: 50 }}
        whileInView={{ opacity: 1, scale: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{
          duration: 1,
          mass: 1,
          delay: 0.5,
        }}
      >
        {t("footerTitle")}.
      </Title>
    </Section>
  );
};

export default Banner;
