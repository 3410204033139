import React from "react";
import {
  Container,
  ImageContainer,
  Title,
  PlayIcon,
  Nav,
  LeftContainer,
  MenuItem,
  LogoContainer,
  LogoImg,
  LogoText,
  Img,
  Header,
} from "./ImagesElements";
import { BsPlayCircle } from "react-icons/bs";
import { BiExit } from "react-icons/bi";
import logoImg from "../../image/Logo/logoImg_light.png";
import logoText from "../../image/Logo/logoText_light.png";
import { Link } from "react-scroll";

const index = ({ exitVideo, photos, title }) => {
  const initial = { opacity: 0, scale: 0.618, rotate: -15, x: -25 };
  const whileInView = { opacity: 1, scale: 1, rotate: 0, x: 0 };
  const viewport = { once: true };
  const transition = {
    duration: 0.8,
    ease: "easeInOut",
    mass: 0.3,
    delay: 0.2,
  };

  return (
    <Container>
      <Nav>
        <LeftContainer>
          <MenuItem>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <LogoContainer>
                <LogoImg
                  src={logoImg}
                  initial={{ opacity: 0, scale: 0, y: "-100", rotate: -180 }}
                  whileInView={{ opacity: 1, scale: 1, y: "0", rotate: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 2,
                    bounce: 0.3,
                    mass: 1,
                  }}
                />
                <LogoText
                  src={logoText}
                  initial={{ opacity: 0, scale: 1 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 2,
                    bounce: 0.3,
                    mass: 1,
                    delay: 2,
                  }}
                />
              </LogoContainer>
            </Link>
          </MenuItem>
        </LeftContainer>
        <BiExit size={36} color="white" onClick={() => exitVideo()} />
      </Nav>
      <Header>{title}</Header>
      <ImageContainer>
        {photos.map((photo, index) => (
          <Img
            key={index}
            src={photo}
            alt={`Photo ${index}`}
            initial={initial}
            whileInView={whileInView}
            viewport={viewport}
            transition={transition}
          />
        ))}
      </ImageContainer>
    </Container>
  );
};

export default index;
