import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: ${(props) => props.theme.body};

  h3 {
    width: 50%;
    text-align: center;
    line-height: 2;
    font-weight: 400;

    @media (max-width: 1024px) {
      width: 90%;
      font-size: 16px;
    }
  }
`;

export const Title = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: 40px;
  margin-bottom: 3rem;
  border-bottom: 1px solid ${(props) => props.theme.text};
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.text};
`;
