import React from "react";
import { Container, Title, Content } from "./AboutElements";
import { motion } from "framer-motion";

const index = ({ t }) => {
  return (
    <Container id="about">
      <Content
        initial={{ opacity: 0, scale: 1, x: 100, rotate: 20 }}
        whileInView={{ opacity: 1, scale: 1, x: 0, rotate: 0 }}
        viewport={{ once: true }}
        transition={{
          duration: 0.4,
          mass: 1,
          delay: 0.2,
        }}
      >
        <Title>{t("aboutTitle")}</Title>
        <motion.h3
          initial={{ opacity: 0, scale: 1, x: -100 }}
          whileInView={{ opacity: 1, scale: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.9,
            mass: 0.8,
            delay: 0.5,
          }}
        >
          {t("introduction")}
        </motion.h3>
      </Content>
    </Container>
  );
};

export default index;
