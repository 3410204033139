import React, { useState } from "react";
import styled from "styled-components";
import cover1 from "../../image/Works/dzzit1cover.jpg";
import cover2 from "../../image/Works/guyu1cover.jpg";
import cover3 from "../../image/Works/libresse1cover.jpg";
import cover4 from "../../image/Works/neta1cover.jpg";
import cover5 from "../../image/Works/fila1cover.jpg";
import cover6 from "../../image/Works/skinCeuticals1cover.jpg";
import cover7 from "../../image/Works/omega1cover.jpg";
import { BsPlayCircle } from "react-icons/bs";
import { motion } from "framer-motion";

const Container = styled(motion.div)`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.body};
  margin-bottom: 5rem;
  overflow-x: hidden;
`;

const VideoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0;
  padding: 5rem 0;
  position: relative;
`;

const ImageContainer = styled.div`
  width: 60%;
  height: 24rem;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0.3;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
  }

  &:hover {
    scale: 1.03;
    transition: 0.5s;
    transition-delay: 0s;
    opacity: 0.9;
  }
`;

const Title = styled.h1`
  color: ${(props) => props.theme.text};
  position: absolute;
  left: 15%;
  top: 55%;
  font-size: 60px;
  z-index: 10;
  text-shadow: 3px 3px 3px ${(props) => props.theme.body};

  @media only screen and (max-width: 1024px) {
    width: 100%;
    position: absolute;
    left: 5%;
    top: 65%;
    font-size: 30px;
  }
`;

const Subtitle = styled.h1`
  width: 60%;
  color: ${(props) => props.theme.text};
  font-size: 24px;
  font-weight: 400;
  z-index: 10;
  text-shadow: 3px 3px 3px ${(props) => props.theme.body};
  text-align: right;
  margin: 0.75rem 0;
  padding-right: 1.5rem;
  opacity: 0.75;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    font-size: 18px;
  }
`;

const PlayIcon = styled.h1`
  color: ${(props) => props.theme.text};
  position: absolute;
  z-index: 10;
  text-shadow: 3px 3px 3px ${(props) => props.theme.body};
  opacity: 0.75;
  &:hover {
    scale: 1.03;
    transition: 0.5s;
    transition-delay: 0s;
    opacity: 0.9;
    color: ${(props) => props.theme.body};
  }
`;

const Header = styled.h1`
  color: ${(props) => props.theme.text};
  font-size: 60px;
  font-weight: 500;
  margin-top: 5rem;
  width: 100%;
  text-align: center;

  @media only screen and (max-width: 1024px) {
    font-size: 48px;
    height: auto;
  }
`;

const China = ({
  t,
  toggleVideo_dzzit,
  toggleVideo_guyu,
  toggleVideo_libresse,
  toggleVideo_skinC,
  toggleImg_fila,
  toggleVideo_neta,
  toggleVideo_omega1,
}) => {
  const initial = { opacity: 0, scale: 1 , y: 100 };
  const whileInView = { opacity: 1, scale: 1, y: 0 };
  const viewport = { once: true };
  const transition = {
    duration: 0.8,
    ease: "easeInOut",
    mass: 0.3,
    delay: 0.2,
  };

  return (
    <Container
      initial={initial}
      whileInView={whileInView}
      viewport={viewport}
      transition={transition}
    >
      <Header>{t("workFilms")}</Header>

      <VideoContainer onClick={() => toggleVideo_neta()}>
        <Title>哪吒汽车 (Neta Auto)</Title>
        <PlayIcon>
          <BsPlayCircle size={100} />
        </PlayIcon>
        <ImageContainer>
          <img src={cover4} width="100%" />
        </ImageContainer>
        <Subtitle>{t("categoryAuto")}</Subtitle>
      </VideoContainer>

      <VideoContainer onClick={() => toggleVideo_omega1()}>
        <Title>欧米茄 (Omega)</Title>
        <PlayIcon>
          <BsPlayCircle size={100} />
        </PlayIcon>
        <ImageContainer>
          <img src={cover7} width="100%" />
        </ImageContainer>
        <Subtitle>{t("categoryWatch")}</Subtitle>
      </VideoContainer>

      <VideoContainer onClick={() => toggleVideo_libresse()}>
        <Title>薇尔 (Libresse)</Title>
        <PlayIcon>
          <BsPlayCircle size={100} />
        </PlayIcon>
        <ImageContainer>
          <img src={cover3} width="100%" />
        </ImageContainer>
        <Subtitle>{t("categoryConsumerProduct")}</Subtitle>
      </VideoContainer>

      <VideoContainer onClick={() => toggleVideo_skinC()}>
        <Title>修丽可 (Skin Ceuticals)</Title>
        <PlayIcon>
          <BsPlayCircle size={100} />
        </PlayIcon>
        <ImageContainer>
          <img src={cover6} width="100%" />
        </ImageContainer>
        <Subtitle>{t("categoryCosmetic")}</Subtitle>
      </VideoContainer>

      <VideoContainer onClick={() => toggleVideo_guyu()}>
        <Title>谷雨</Title>
        <PlayIcon>
          <BsPlayCircle size={100} />
        </PlayIcon>
        <ImageContainer>
          <img src={cover2} width="100%" />
        </ImageContainer>
        <Subtitle>{t("categoryCosmetic")}</Subtitle>
      </VideoContainer>

      <VideoContainer onClick={() => toggleVideo_dzzit()}>
        <Title>d'zzit</Title>
        <PlayIcon>
          <BsPlayCircle size={100} />
        </PlayIcon>
        <ImageContainer>
          <img src={cover1} width="100%" />
        </ImageContainer>
        <Subtitle>{t("categoryFashion")}</Subtitle>
      </VideoContainer>

      <Header>{t("workPhoto")}</Header>
      <VideoContainer onClick={() => toggleImg_fila()}>
        <Title>Fila Golf</Title>
        <PlayIcon>
          <BsPlayCircle size={100} />
        </PlayIcon>
        <ImageContainer>
          <img src={cover5} width="100%" />
        </ImageContainer>
        <Subtitle>{t("categoryFashion")}</Subtitle>
      </VideoContainer>
    </Container>
  );
};

export default China;
